import React from "react";
import * as Sentry from "@sentry/node";
import { SWRConfig } from "swr";
import { RewriteFrames } from "@sentry/integrations";
import getConfig from "next/config";
import { ThemeProvider } from "styled-components";
import { GlobalStyles, theme } from "../components/styles/GlobalStyles.js";
import fetch from "lib/fetchJson";
import "../styles/global.less";
import "react-image-gallery/styles/css/image-gallery.css";

if (process.env.NEXT_PUBLIC_SENTRY_DSN) {
  const config = getConfig()
  const distDir = `${config.serverRuntimeConfig.rootDir}/.next`
  Sentry.init({
    enabled: process.env.NODE_ENV === 'production',
    integrations: [
      new RewriteFrames({
        iteratee: (frame) => {
          frame.filename = frame.filename.replace(distDir, 'app:///_next')
          return frame
        },
      }),
    ],
    dsn: process.env.NEXT_PUBLIC_SENTRY_DSN,
  })
}

export default function App({ Component, pageProps, err }) {
  // Workaround for https://github.com/vercel/next.js/issues/8592
  return  (
    <>
      <SWRConfig
        value={{
          fetcher: fetch,
          onError: (err) => {
            console.error(err)
          },
        }}
      >
        <GlobalStyles />
        <ThemeProvider theme={theme}>
          <Component {...pageProps} err={err} />
        </ThemeProvider>
      </SWRConfig>
    </>
  )
}